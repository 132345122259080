'use client';

import { createContext, useReducer, useEffect } from 'react';

const DataContext = createContext();

function getInitialState() {
  const state = localStorage.getItem('sharedState');
  return state ? JSON.parse(state) : {};
}

const events = {
  SET_STATE: 'SET_STATE',
  CLEAR_STATE: 'CLEAR_STATE',
};

function stateReducer(state, action) {
  switch (action.type) {
    case events.SET_STATE:
      return { ...state, ...action.payload };
    case events.CLEAR_STATE:
      return {};
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}


const setData = (dispatch, payload) => {
  dispatch({ type: events.SET_STATE, payload });
};

const clearData = (dispatch) => {
  dispatch({ type: events.CLEAR_STATE });
};

const DataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, {}, getInitialState);

  useEffect(() => {
    localStorage.setItem('sharedState', JSON.stringify(state));
  }, [state]);

  const actionCreators = {
    setData: (payload) => setData(dispatch, payload),
    clearData: () => clearData(dispatch),
  };

  return (
    <DataContext.Provider value={{ state, dispatch, actions: actionCreators }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext };
export default DataContextProvider;
